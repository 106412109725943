import axios from "axios"
if (localStorage.getItem("userAuthBibliotheque")) {
  var userAuthBibliotheque = JSON.parse(localStorage.getItem("userAuthBibliotheque"))
  var token = userAuthBibliotheque.user.api_token
}

export default axios.create({
  withCredentials: true,
  baseURL: `https://backbibliotheque.isggb.com/api/`,
  headers: {
    Authorization: `Bearer ${token}`,
    apipassword: "ux3JxHFwmKQsBpXrzgLHJ",

  },
})

import React, { useState, useEffect, useMemo } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../apis"
import {
  Button,
  Col,
  Input,
  Row,
  Card,
  CardBody,
  Container,
  Spinner,
  CardTitle,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withTranslation } from "react-i18next"

const EditeurLivres = props => {
  const [arrayLivre, setArrayLivre] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("editeur/get_livre", { id }).then(res => {
      setArrayLivre(res.data.BibliothequeTheme)
      setLoading(true)
    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading ? (
                  <Row>
                    <CardTitle className="h4">Les livres</CardTitle>
                    <table className="table table-bordered mt-4">
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }} scope="col">
                            Titre
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Auteur
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Lieu_ dition
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Editeur
                          </th>
                          <th style={{ textAlign: "center" }} scope="col">
                            Date edition
                          </th>
                        </tr>
                      </thead>
                      {arrayLivre.map((el, index) => (
                        <tbody key={index}>
                          <tr>
                            <td className="col-md-2">
                              <Input
                                type="text"
                                className="form-control"
                                value={el.titre}
                              />
                            </td>
                            <td className="col-md-2">
                              <Input
                                type="text"
                                className="form-control"
                                value={el.auteur.libelle_fr}
                              />
                            </td>
                            <td className="col-md-2">
                              <Input
                                type="text"
                                className="form-control"
                                value={el.lieu_edition}
                              />
                            </td>
                            <td className="col-md-2">
                              <Input
                                type="text"
                                className="form-control"
                                value={el.editeur.libelle_fr}
                              />
                            </td>
                            <td className="col-md-2">
                              <Input
                                type="text"
                                className="form-control"
                                value={el.date_edition}
                              />
                            </td>
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <Col lg="6">
                        <div className="text-center mt-4">
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() => props.history.push("/Editeur")}
                          >
                            {props.t("Annuler")}
                          </Button>
                        </div>
                      </Col>
                    </div>
                  </Row>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {" "}
                      {"Chargement"} ...{" "}
                    </h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(EditeurLivres))
EditeurLivres.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../apis"
import { withTranslation } from "react-i18next"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"

const EditLivre = props => {
  const [numero, setNumero] = useState("")
  const [titre, setTitre] = useState("")
  const [auteurs, setAuteurs] = useState([])
  const [selectAuteur, setSelectAuteur] = useState("")
  const [cote, setCote] = useState("")
  const [editeurs, setEditeurs] = useState([])
  const [selectEditeur, setSelectEditeur] = useState("")
  const [lieu, setLieu] = useState("")
  const [date, setDate] = useState("")
  const [ISBN, setISBN] = useState("")
  const [themes, setThemes] = useState([])
  const [selectTheme, setSelectTheme] = useState("")
  const [loading, setLoading] = useState(false)
  const [motCle, setMotCle] = useState([])
  const [selectMotCle, setSelectMotCle] = useState("")
  const [nombre, setNombre] = useState("")

  useEffect(async () => {
    const resT = await API.get("theme/select").then(resT => {
      setThemes(resT.data.BibliothequeTheme)
    })
    const resA = await API.get("auteur/select").then(resA => {
      setAuteurs(resA.data.auteur)
    })
    const resE = await API.get("editeur/select").then(resE => {
      setEditeurs(resE.data.editeur)
    })
    const resM = await API.get("mot/select").then(resM => {
      setMotCle(resM.data.MotCle)
    })
    //
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("livre/get_by_id", { id }).then(res => {
      setNumero(res.data.Livre.num)
      setTitre(res.data.Livre.titre)
      setSelectAuteur(res.data.Livre.auteur)
      setCote(res.data.Livre.cote)
      setSelectEditeur(res.data.Livre.editeur)
      setLieu(res.data.Livre.lieu_edition)
      setDate(new Date(res.data.Livre.date_edition))
      setISBN(res.data.Livre.isbn)
      setSelectTheme(res.data.Livre.BibliothequeTheme)
      setSelectMotCle(res.data.Livre.motcle_id)
      setNombre(res.data.Livre.nb_exemplaire)
      setLoading(true)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (date != "") {
      let dateEdition = date
      let month = "" + (dateEdition.getMonth() + 1)
      let day = "" + dateEdition.getDate()
      let year = dateEdition.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }
    if (numero == "") {
      toast.error("⛔ Numéro obligatoire", {
        containerId: "A",
      })
    } else if (titre == "") {
      toast.error("⛔ Titre obligatoire", {
        containerId: "A",
      })
    } else if (selectAuteur != "") {
      const res = await API.post("livre/edit", {
        id: id,
        num: numero,
        titre: titre,
        auteur: selectAuteur.value,
        cote: cote,
        editeur: selectEditeur.value,
        lieu_edition: lieu,
        date_edition: convertDate,
        isbn: ISBN,
        bibliotheque_theme_id: selectTheme.value,
        mot_cle: selectMotCle,
        nb_exemplaire: nombre,
      }).then(res => {
        props.history.push("/Livres")
      })
    } else {
      toast.error("⛔ Auteur obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading ? (
                  <Row>
                    <Form>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Numéro")} :
                            </Label>
                            <Input
                              lg="3"
                              type="number"
                              className="form-control"
                              placeholder={props.t("Numéro")}
                              value={numero}
                              onChange={e => setNumero(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Titre")} :
                            </Label>
                            <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              placeholder={props.t("Titre")}
                              value={titre}
                              onChange={e => setTitre(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Auteur")} :
                            </Label>
                            <Select
                              options={auteurs}
                              isSearchable={true}
                              value={selectAuteur}
                              onChange={e => setSelectAuteur(e)}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Cote")} :
                            </Label>
                            <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              placeholder={props.t("Cote")}
                              value={cote}
                              onChange={e => setCote(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Editeur")} :
                            </Label>
                            <Select
                              options={editeurs}
                              isSearchable={true}
                              value={selectEditeur}
                              onChange={e => setSelectEditeur(e)}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Lieu edition")} :
                            </Label>
                            <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              placeholder={props.t("Lieu edition")}
                              value={lieu}
                              onChange={e => setLieu(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Date edition")} :
                            </Label>
                            <Col md={12} className="pr-0">
                              <DatePicker
                                className="form-control ddate"
                                selected={date}
                                onChange={e => setDate(e)}
                                value={date}
                                dateFormat="dd/MM/yyyy"
                              />
                            </Col>
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("ISBN")} :
                            </Label>
                            <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              placeholder={props.t("ISBN")}
                              value={ISBN}
                              onChange={e => setISBN(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Nombre exemplaire")} :
                            </Label>
                            <Input
                              lg="3"
                              type="number"
                              value={nombre}
                              className="form-control"
                              placeholder={props.t("Nombre exemplaire")}
                              onChange={e => setNombre(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg="6">
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Mot clé")}
                            </Label>
                            <Select
                              options={motCle}
                              isSearchable={true}
                              isClearable={true}
                              value={selectMotCle}
                              onChange={e => setSelectMotCle(e)}
                              isMulti={true}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Thème ")} :
                            </Label>
                            <Select
                              options={themes}
                              isSearchable={true}
                              value={selectTheme}
                              onChange={e => setSelectTheme(e)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="text-center mt-4"
                        >
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() => props.history.push("/Livres")}
                          >
                            {props.t("Annuler")}
                          </Button>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          className="text-center mt-4"
                        >
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={edit}
                          >
                            {props.t("Confirmer")}
                          </button>
                        </div>
                      </Col>
                    </div>
                  </Row>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {" "}
                      {"Chargement"} ...{" "}
                    </h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditLivre)
EditLivre.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}

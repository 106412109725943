import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../apis"
import { withTranslation } from "react-i18next"
//Import Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import Select from "react-select"

const AddLivre = props => {
  const [numero, setNumero] = useState("")
  const [titre, setTitre] = useState("")
  const [auteurs, setAuteurs] = useState([])
  const [selectAuteur, setSelectAuteur] = useState("")
  const [cote, setCote] = useState("")
  const [editeurs, setEditeurs] = useState([])
  const [selectEditeur, setSelectEditeur] = useState("")
  const [lieu, setLieu] = useState("")
  const [date, setDate] = useState("")
  const [ISBN, setISBN] = useState("")
  const [nombre, setNombre] = useState("")
  const [themes, setThemes] = useState([])
  const [selectTheme, setSelectTheme] = useState("")
  const [motCle, setMotCle] = useState([])
  const [selectMotCle, setSelectMotCle] = useState("")
  const [modal, setModal] = useState(false)
  const [modalAuteur, setModalAuteur] = useState(false)
  const [modalEditeur, setModalEditeur] = useState(false)
  const [modalSupp, setModalSupp] = useState(false)
  const [libelle, setLibelle] = useState("")
  const [libelle1, setLibelle1] = useState("")
  const [indexLigne, setIndexLigne] = useState("")
  const [arrayMot, setArrayMot] = useState([
    {
      motCle: "",
    },
  ])

  useEffect(async () => {
    const res = await API.get("theme/select").then(res => {
      setThemes(res.data.BibliothequeTheme)
    })
    const resA = await API.get("auteur/select").then(resA => {
      setAuteurs(resA.data.auteur)
    })
    const resE = await API.get("editeur/select").then(resE => {
      setEditeurs(resE.data.editeur)
    })
    const resM = await API.get("mot/select").then(resM => {
      setMotCle(resM.data.MotCle)
    })
  }, [])

  const save = async () => {
    if (date != "") {
      let dateEdition = date
      let month = "" + (dateEdition.getMonth() + 1)
      let day = "" + dateEdition.getDate()
      let year = dateEdition.getFullYear()
      if (month.length < 2) month = "0" + month
      if (day.length < 2) day = "0" + day
      var convertDate = [year, month, day].join("-")
    } else {
      var convertDate = ""
    }
    if (numero == "") {
      toast.error("⛔ Numéro obligatoire", {
        containerId: "A",
      })
    } else if (titre == "") {
      toast.error("⛔ Titre obligatoire", {
        containerId: "A",
      })
    } else if (selectAuteur != "") {
      const res = await API.post("livre/add", {
        num: numero,
        titre: titre,
        auteur: selectAuteur.value,
        cote: cote,
        editeur: selectEditeur.value,
        lieu_edition: lieu,
        date_edition: convertDate,
        isbn: ISBN,
        bibliotheque_theme_id: selectTheme.value,
        mot_cle: selectMotCle,
        nb_exemplaire: nombre,
      }).then(res => {
        props.setSection(1)
      })
    } else {
      toast.error("⛔ Auteur obligatoire", {
        containerId: "A",
      })
    }
  }

  const toggle = () => {
    setModal(!modal)
  }
  const toggleEditeur = () => {
    setModalEditeur(!modalEditeur)
  }
  const toggleAuteur = () => {
    setModalAuteur(!modalAuteur)
  }

  const addLigne = () => {
    let element = {
      motCle: "",
    }
    setArrayMot([...arrayMot, element])
  }

  const toggleSupp = indexOfLigne => {
    setModalSupp(!modalSupp)
    setIndexLigne(indexOfLigne)
  }

  const toggleDelete = async () => {
    arrayMot.splice(indexLigne, 1)
    setModalSupp(false)
  }

  const addMotCle = (event, index) => {
    let motCle = event.target.value
    setArrayMot(
      arrayMot.map((el, id) =>
        id === index ? Object.assign(el, { motCle: motCle }) : el
      )
    )
  }

  const saveM = async () => {
    console.log("hello")
    var etat = true
    for (let i = 0; i < arrayMot.length; i++) {
      var motCle = arrayMot[i].motCle
      if (motCle == "") {
        var etat = false
        toast.error("⛔ Mot clé obligatoire", {
          containerId: "A",
        })
      }
    }
    if (arrayMot.length == 0) {
      var etat = false
      toast.error("⛔ Saisir au moins une mot clé", {
        containerId: "A",
      })
    }
    if (etat) {
      const res = await API.post("mot/add", {
        motCle: arrayMot,
      }).then(res => {
        setModal(!modal)
        const resM = API.get("mot/select").then(resM => {
          setMotCle(resM.data.MotCle)
        })
      })
    }
  }

  const saveEditeur = async () => {
    if (libelle != "") {
      const res = await API.post("editeur/add", {
        libelle: libelle,
      }).then(res => {
        setModalEditeur(!modalEditeur)
        const resE = API.get("editeur/select").then(resE => {
          setEditeurs(resE.data.editeur)
        })
      })
    } else {
      toast.error("⛔ Editeur obligatoire", {
        containerId: "A",
      })
    }
  }

  const saveAuteur = async () => {
    if (libelle1 != "") {
      const res = await API.post("auteur/add", {
        libelle: libelle1,
      }).then(res => {
        setModalAuteur(!modalAuteur)
        const resA = API.get("auteur/select").then(resA => {
          setAuteurs(resA.data.auteur)
        })
      })
    } else {
      toast.error("⛔ Auteur obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Numéro")} :
                </Label>
                <Input
                  lg="3"
                  type="number"
                  className="form-control"
                  placeholder={props.t("Numéro")}
                  onChange={e => setNumero(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Titre")} :
                </Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  placeholder={props.t("Titre")}
                  onChange={e => setTitre(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Auteur")}
                </Label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "95%" }}>
                    <Select
                      options={auteurs}
                      isSearchable={true}
                      onChange={e => setSelectAuteur(e)}
                    />
                  </div>
                  <div style={{ width: "3%" }}>
                    <button
                      type="button"
                      className="btn btn-light position-relative p-0 avatar-xs rounded-circle"
                      style={{ backgroundColor: "cornflowerblue" }}
                    >
                      <span
                        onClick={toggleAuteur}
                        className="avatar-title bg-transparent text-reset"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fas fa-plus"
                        ></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Cote")} :
                </Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  placeholder={props.t("Cote")}
                  onChange={e => setCote(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Editeur")}
                </Label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "95%" }}>
                    <Select
                      options={editeurs}
                      isSearchable={true}
                      onChange={e => setSelectEditeur(e)}
                    />
                  </div>
                  <div style={{ width: "3%" }}>
                    <button
                      type="button"
                      className="btn btn-light position-relative p-0 avatar-xs rounded-circle"
                      style={{ backgroundColor: "cornflowerblue" }}
                    >
                      <span
                        onClick={toggleEditeur}
                        className="avatar-title bg-transparent text-reset"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fas fa-plus"
                        ></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Lieu edition")} :
                </Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  placeholder={props.t("Lieu edition")}
                  onChange={e => setLieu(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Date edition")} :
                </Label>
                <Col md={12} className="pr-0">
                  <DatePicker
                    className="form-control ddate"
                    selected={date}
                    onChange={e => setDate(e)}
                    dateFormat="dd/MM/yyyy"
                  />
                </Col>
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("ISBN")} :
                </Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  placeholder={props.t("ISBN")}
                  onChange={e => setISBN(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Nombre exemplaire")} :
                </Label>
                <Input
                  lg="3"
                  type="number"
                  className="form-control"
                  placeholder={props.t("Nombre exemplaire")}
                  onChange={e => setNombre(e.target.value)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Mot clé")}
                </Label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ width: "95%" }}>
                    <Select
                      options={motCle}
                      isSearchable={true}
                      isClearable={true}
                      onChange={e => setSelectMotCle(e)}
                      isMulti={true}
                    />
                  </div>
                  <div style={{ width: "3%" }}>
                    <button
                      type="button"
                      className="btn btn-light position-relative p-0 avatar-xs rounded-circle"
                      style={{ backgroundColor: "cornflowerblue" }}
                    >
                      <span
                        onClick={toggle}
                        className="avatar-title bg-transparent text-reset"
                      >
                        <i
                          style={{ color: "white" }}
                          className="fas fa-plus"
                        ></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Thème ")} :
                </Label>
                <Select
                  options={themes}
                  isSearchable={true}
                  onChange={e => setSelectTheme(e)}
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                {props.t("Annuler")}
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                {props.t("Confirmer")}
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
      <Modal
        style={{
          maxWidth: "40%",
          width: "100%",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        isOpen={modal}
        toggle={toggle}
        centered={true}
      >
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggle}
        >
          {props.t("Ajouter un nouveau mot clé")}
        </div>
        <ModalBody>
          <Row>
            <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }} scope="col">
                    Mot clé
                  </th>
                  <th style={{ textAlign: "center" }} scope="col">
                    <i
                      style={{
                        color: "#a0ceb8",
                        cursor: "pointer",
                      }}
                      className="fas fa-plus"
                      onClick={addLigne}
                    />
                  </th>
                </tr>
              </thead>
              {arrayMot.map((el, index) => (
                <tbody key={index}>
                  <tr>
                    <td className="col-md-10">
                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Mot clé"
                        onChange={e => addMotCle(e, index)}
                      />
                    </td>
                    <td className="col-md-2" style={{ textAlign: "center" }}>
                      <i
                        style={{ color: "#cc0000", cursor: "pointer" }}
                        className="mdi mdi-delete font-size-18"
                        id="deletetooltip"
                        onClick={() => toggleSupp(index)}
                      />
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={toggle}
                >
                  {props.t("Annuler")}
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveM}
                >
                  {props.t("Confirmer")}
                </button>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        style={{
          maxWidth: "40%",
          width: "60%",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        isOpen={modalEditeur}
        toggle={toggleEditeur}
        centered={true}
      >
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggleEditeur}
        >
          {props.t("Ajouter editeur")}
        </div>
        <ModalBody>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Editeur")} :
                </Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  placeholder={props.t("Editeur")}
                  onChange={e => setLibelle(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={toggleEditeur}
                >
                  {props.t("Annuler")}
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveEditeur}
                >
                  {props.t("Confirmer")}
                </button>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        style={{
          maxWidth: "40%",
          width: "60%",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
        }}
        isOpen={modalAuteur}
        toggle={toggleAuteur}
        centered={true}
      >
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
          }}
          toggle={toggleAuteur}
        >
          {props.t("Ajouter auteur")}
        </div>
        <ModalBody>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Auteur")} :
                </Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  placeholder={props.t("Auteur")}
                  onChange={e => setLibelle1(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="text-center mt-4"
              >
                <Button
                  type="button"
                  color="warning"
                  className="btn btn-warning  mb-2 me-2"
                  onClick={toggleAuteur}
                >
                  {props.t("Annuler")}
                </Button>
              </div>
            </Col>
            <Col lg="6">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
                className="text-center mt-4"
              >
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveAuteur}
                >
                  {props.t("Confirmer")}
                </button>
              </div>
            </Col>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalSupp} toggle={toggleSupp} centered={true}>
        <ModalHeader toggle={toggleSupp} tag="h4">
          {" Suppression d'une ligne"}{" "}
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <p>Voulez-vous supprimer cette ligne ?</p>
            </div>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "space-around",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button
                  onClick={toggleDelete}
                  color="info"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-up"
                  ></i>
                  Oui
                </Button>
              </div>
              <div>
                <Button
                  onClick={toggleSupp}
                  color="danger"
                  className="btn-rounded "
                >
                  <i
                    style={{ color: "white" }}
                    className="far fa-thumbs-down"
                  ></i>
                  Non
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(AddLivre)
AddLivre.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}

import React, { useState, useEffect, useMemo } from "react"
import { withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import API from "../../apis"
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Card,
  CardBody,
  Container,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import { withTranslation } from "react-i18next"

const EditThemes = props => {
  const [libelle, setLibelle] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("theme/get_by_id", { id }).then(res => {
      setLibelle(res.data.BibliothequeTheme.libelle)
      setLoading(true)
    })
  }, [])

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    if (libelle != "") {
      const res = await API.post("theme/edit", {
        id: id,
        libelle: libelle,
      }).then(res => {
        props.history.push("/Themes")
      })
    } else {
      toast.error("⛔ Thème obligatoire", {
        containerId: "A",
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading ? (
                  <Row>
                    <Form>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Thème")} :
                            </Label>
                            <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              value={libelle}
                              placeholder={props.t("Thème")}
                              onChange={e => setLibelle(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {" "}
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="text-center mt-4"
                        >
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() => props.history.push("/Themes")}
                          >
                            {props.t("Annuler")}
                          </Button>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          className="text-center mt-4"
                        >
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={edit}
                          >
                            {props.t("Confirmer")}
                          </button>
                        </div>
                      </Col>
                    </div>
                  </Row>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {" "}
                      {"Chargement"} ...{" "}
                    </h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}
export default withRouter(withTranslation()(EditThemes))
EditThemes.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Button, Col, Form, Input, Label, Row } from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../apis"
import { withTranslation } from "react-i18next"
import Select from "react-select"

const AddMemoirs = props => {
  const [themes, setThemes] = useState([])
  const [selectTheme, setSelectTheme] = useState("")
  const [types, setTypes] = useState([
    { value: 1, label: "Stages" },
    { value: 2, label: "Mémoires" },
  ])
  const [selectType, setSelectType] = useState("")
  const [titre, setTitre] = useState("")
  const [annee, setAnnee] = useState("")
  const [cote, setCote] = useState("")
  const [chercheur, setChercheur] = useState("")
  const [encadreur, setEncadreur] = useState("")
  const [description, setDescription] = useState("")
  const [file, setFile] = useState(null)

  useEffect(async () => {
    const res = await API.get("memoire/select").then(res => {
      setThemes(res.data.themeMemoire)
    })
  }, [])

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const save = async () => {
    var pathFile = ""
    if (file != null) {
      pathFile = file.name
    }
    if (selectType == "") {
      toast.error("⛔ Type obligatoire", {
        containerId: "A",
      })
    } else if (selectTheme == "") {
      toast.error("⛔ Thème obligatoire", {
        containerId: "A",
      })
    } else if (chercheur == "") {
      toast.error("⛔ Chercheur obligatoire", {
        containerId: "A",
      })
    } else if (encadreur == "") {
      toast.error("⛔ Encadreurب obligatoire", {
        containerId: "A",
      })
    } else if (titre != "") {
      const res = await API.post("memoire/stage/add", {
        theme_memoire_id: selectTheme.value,
        type: selectType.value,
        titre: titre,
        cote: cote,
        annee: annee,
        chercheur: chercheur,
        encadreur: encadreur,
        description: description,
        fichier: pathFile,
      }).then(res => {
        props.setSection(1)
        if (pathFile != "") {
          const formData = new FormData()
          formData.append("document", file)
          formData.append("annee", "2022")
          formData.append("entite", "memoires")
          const resA = API.post("file/import", formData)
        }
      })
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Form>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Type ")} :
                </Label>
                <Select
                  options={types}
                  isSearchable={true}
                  onChange={e => setSelectType(e)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Thème ")} :
                </Label>
                <Select
                  options={themes}
                  isSearchable={true}
                  onChange={e => setSelectTheme(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Titre")} :
                </Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  placeholder={props.t("Titre")}
                  onChange={e => setTitre(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Année")} :
                </Label>
                <Input
                  lg="3"
                  type="number"
                  className="form-control"
                  placeholder={props.t("Année")}
                  onChange={e => setAnnee(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Cote")} :
                </Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  placeholder={props.t("Cote")}
                  onChange={e => setCote(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Chercheur")} :
                </Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  placeholder={props.t("Chercheur")}
                  onChange={e => setChercheur(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Encadreur")} :
                </Label>
                <Input
                  lg="3"
                  type="text"
                  className="form-control"
                  placeholder={props.t("Encadreur")}
                  onChange={e => setEncadreur(e.target.value)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Description")} :
                </Label>
                <Input
                  lg="3"
                  type="textarea"
                  className="form-control"
                  placeholder={props.t("Description")}
                  onChange={e => setDescription(e.target.value)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Fichier PDF ")} :
                </Label>
                <Input
                  onChange={e => addFile(e)}
                  className="form-control"
                  type="file"
                  id="formFile"
                />
              </div>
            </Col>
          </Row>
        </Form>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
              className="text-center mt-4"
            >
              <Button
                type="button"
                color="warning"
                className="btn btn-warning  mb-2 me-2"
                onClick={props.back}
              >
                {props.t("Annuler")}
              </Button>
            </div>
          </Col>
          <Col lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
              className="text-center mt-4"
            >
              <button type="button" className="btn btn-primary " onClick={save}>
                {props.t("Confirmer")}
              </button>
            </div>
          </Col>
        </div>
      </Row>
      <ToastContainer
        transition={Slide}
        enableMultiContainer
        containerId={"A"}
        position={toast.POSITION.TOP_RIGHT}
        autoClose={2500}
      />
    </React.Fragment>
  )
}

export default withTranslation()(AddMemoirs)
AddMemoirs.propTypes = {
  history: PropTypes.object,
  back: PropTypes.object,
  setSection: PropTypes.object,
  t: PropTypes.any,
}

import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Themes
import Themes from "../pages/Themes/Themes"
import EditThemes from "../pages/Themes/EditThemes"
import ThemesLivres from "../pages/Themes/ThemesLivres"

// Themes
import ThemesMemoires from "../pages/ThemesMemoires/ThemesMemoires"
import EditThemesMemoires from "../pages/ThemesMemoires/EditThemesMemoires"

// Memoires
import Memoires from "../pages/Memoirs&Stages/Memoires"
import EditMemoires from "../pages/Memoirs&Stages/EditMemoires"

// Livres
import Livres from "../pages/Livres/Livres"
import EditLivre from "../pages/Livres/EditLivre"

// Auteur
import Auteur from "../pages/Auteur/Auteur"
import EditAuteur from "../pages/Auteur/EditAuteur"
import AuteurLivres from "../pages/Auteur/AuteurLivres"

// Editeur
import Editeur from "../pages/Editeur/Editeur"
import EditEditeur from "../pages/Editeur/EditEditeur"
import EditeurLivres from "../pages/Editeur/EditeurLivres"

// Demande
import Demande from "../pages/Demandes/Demande"

// Emprunte
import Emprunte from "../pages/Emprunte/Emprunte"

// Retour
import Retour from "../pages/Retour/Retour"

// Livre en retard
import LivreRetard from "../pages/LivreRetard/LivreRetard"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  { path: "/Themes", component: Themes },
  { path: "/EditThemes", component: EditThemes },
  { path: "/ThemesLivres", component: ThemesLivres },

  { path: "/ThemesMemoires", component: ThemesMemoires },
  { path: "/EditThemesMemoires", component: EditThemesMemoires },

  { path: "/Memoires", component: Memoires },
  { path: "/EditMemoires", component: EditMemoires },

  { path: "/Livres", component: Livres },
  { path: "/EditLivre", component: EditLivre },

  { path: "/Auteur", component: Auteur },
  { path: "/EditAuteur", component: EditAuteur },
  { path: "/AuteurLivres", component: AuteurLivres },

  { path: "/Editeur", component: Editeur },
  { path: "/EditEditeur", component: EditEditeur },
  { path: "/EditeurLivres", component: EditeurLivres },
  
  { path: "/Demande", component: Demande },

  { path: "/Emprunte", component: Emprunte },

  { path: "/Retour", component: Retour },

  { path: "/LivreRetard", component: LivreRetard },

  // profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../apis"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Row, Col, Spinner, Modal, ModalBody, Button } from "reactstrap"
import { withRouter } from "react-router"
//i18n
import { withTranslation } from "react-i18next"

const DataTableLivreRetardEtud = props => {
  const [loading, setLoading] = useState(false)
  const [orders, setOrders] = useState([])
  const [modal, setModal] = useState(false)
  const [etudiant, setEtudiantId] = useState("")
  const [nom, setNom] = useState("")
  const [prenom, setPrenom] = useState("")
  const [numero, setNumero] = useState("")
  const [email, setEmail] = useState("")
  const [telephone, setTelephone] = useState("")
  const [addressStreet, setAddressStreet] = useState()

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }

  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "etudiant",
      text: "Etudiant",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date_debut",
      text: "Date Début",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "date_fin",
      text: "Date Fin",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "livre",
      text: "Livre",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "En retard",
      isDummyField: true,
      text: "En retard",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <i
          className="fas fa-times-circle"
          title="Livre en retard"
          style={{ color: "tomato", cursor: "pointer" }}
        />
      ),
    },
    {
      dataField: "Exploration",
      isDummyField: true,
      text: "Exploration",
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i
            style={{ color: "darkcyan", cursor: "pointer" }}
            className="fas fa-eye"
            onClick={() => toggle(row.etudiant_id)}
          ></i>
        </div>
      ),
    },
  ]

  const { SearchBar } = Search

  useEffect(async () => {
    const res = await API.get("reservation/etudiant/retard").then(res => {
      setOrders(res.data.Reservation)
    })
    setLoading(true)
  }, [])

  const toggle = async id => {
    setModal(!modal)
    setEtudiantId(id)
    const res = await API.post("etudiant/get_by_id", {
      id: id,
    }).then(res => {
      setNom(res.data.etudiant.firstName)
      setPrenom(res.data.etudiant.lastName)
      setNumero(res.data.etudiant.code)
      setAddressStreet(res.data.etudiant.addressStreet)
      setEmail(res.data.etudiant.email)
      setTelephone(res.data.etudiant.telephone)
    })
  }

  const toggleClose = () => {
    setModal(!modal)
  }

  return (
    <React.Fragment>
      <div>
        {loading ? (
          <Row>
            <Col xs="12">
              <ToolkitProvider
                keyField="id"
                data={orders}
                columns={Columns()}
                search
                bootstrap4
              >
                {toolkitProps => (
                  <div>
                    <Col sm="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <BootstrapTable
                      wrapperClasses="table-responsive"
                      noDataIndication={() => <NoDataIndication />}
                      striped={false}
                      bordered={false}
                      classes={"table align-middle table-nowrap"}
                      headerWrapperClasses={"table-light"}
                      hover
                      pagination={paginationFactory(pageOptions)}
                      {...toolkitProps.baseProps}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              En cours ...{" "}
            </h4>
          </div>
        )}
      </div>
      <Modal isOpen={modal} toggle={toggleClose} centered={true}>
        <div
          className="modal-header"
          style={{
            width: "100% !important",
            justifyContent: "center !important",
            margin: "0 auto",
            fontSize: "17px",
            fontWeight: "bold",
          }}
          toggle={toggleClose}
        >
          {props.t("Information perseonnelle ")}
        </div>
        <ModalBody>
          <div
            style={{
              fontFamily: "Montserrat, sans-serif",
              FontSize: "14px",
              FontWeight: "700",
              LineHeight: "18.375px",
            }}
          >
            <Row className="mt-4">
              <Col md={6}>
                <h5> Nom et prénom : </h5>
              </Col>
              <Col md={6}>
                <span>
                  {" "}
                  {nom} {prenom}
                </span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <h5> CIN : </h5>
              </Col>
              <Col md={6}>
                <span> {numero} </span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <h5> Téléphone : </h5>
              </Col>
              <Col md={6}>
                <span> {telephone} </span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <h5> Email : </h5>
              </Col>
              <Col md={6}>
                <span> {email} </span>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={6}>
                <h5> Adresse : </h5>
              </Col>
              <Col md={6}>
                <span> {addressStreet} </span>
              </Col>
            </Row>
            <div
              className="hvr-push"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <div>
                <Button onClick={toggleClose} className="btn btn-warning">
                  <i
                    style={{ color: "white", paddingRight: "5px" }}
                    className="fas fa-times-circle"
                  ></i>
                  {props.t("Annuler")}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableLivreRetardEtud))
DataTableLivreRetardEtud.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}

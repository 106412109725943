import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Slide } from "react-toastify"
import API from "../../apis"
import { withTranslation } from "react-i18next"
import Select from "react-select"

const EditMemoires = props => {
  const [themes, setThemes] = useState([])
  const [selectTheme, setSelectTheme] = useState("")
  const [types, setTypes] = useState([
    { value: 1, label: "Stages" },
    { value: 2, label: "Mémoires" },
  ])
  const [selectType, setSelectType] = useState("")
  const [titre, setTitre] = useState("")
  const [annee, setAnnee] = useState("")
  const [cote, setCote] = useState("")
  const [chercheur, setChercheur] = useState("")
  const [encadreur, setEncadreur] = useState("")
  const [description, setDescription] = useState("")
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(async () => {
    const resT = await API.get("memoire/select").then(resT => {
      setThemes(resT.data.themeMemoire)
    })
    //
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("memoire/stage/get_by_id", { id }).then(res => {
      setSelectTheme(res.data.memoireStage.theme_memoire_id)
      setSelectType(res.data.memoireStage.type)
      setTitre(res.data.memoireStage.titre)
      setAnnee(res.data.memoireStage.annee)
      setCote(res.data.memoireStage.cote)
      setChercheur(res.data.memoireStage.chercheur)
      setEncadreur(res.data.memoireStage.encadreur)
      setDescription(res.data.memoireStage.description)
      setLoading(true)
    })
  }, [])

  const addFile = e => {
    var file = e.target.files[0]
    setFile(file)
  }

  const edit = async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    var pathFile = ""
    if (file != null) {
      pathFile = file.name
    }
    if (selectType == "") {
      toast.error("⛔ Type obligatoire", {
        containerId: "A",
      })
    } else if (selectTheme == "") {
      toast.error("⛔ Thème obligatoire", {
        containerId: "A",
      })
    } else if (chercheur == "") {
      toast.error("⛔ Chercheur obligatoire", {
        containerId: "A",
      })
    } else if (encadreur == "") {
      toast.error("⛔ Encadreur obligatoire", {
        containerId: "A",
      })
    } else if (titre != "") {
      const res = await API.post("memoire/stage/edit", {
        id: id,
        theme_memoire_id: selectTheme.value,
        type: selectType.value,
        titre: titre,
        cote: cote,
        annee: annee,
        chercheur: chercheur,
        encadreur: encadreur,
        description: description,
        fichier: pathFile,
      }).then(res => {
        props.history.push("/Memoires")
      })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Card>
              <CardBody>
                {loading ? (
                  <Row>
                    <Form>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Type ")} :
                            </Label>
                            <Select
                              options={types}
                              isSearchable={true}
                              value={selectType}
                              onChange={e => setSelectType(e)}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Thème ")} :
                            </Label>
                            <Select
                              options={themes}
                              isSearchable={true}
                              value={selectTheme}
                              onChange={e => setSelectTheme(e)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Titre")} :
                            </Label>
                            <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              placeholder={props.t("Titre")}
                              value={titre}
                              onChange={e => setTitre(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Année")} :
                            </Label>
                            <Input
                              lg="3"
                              type="number"
                              className="form-control"
                              placeholder={props.t("Année")}
                              value={annee}
                              onChange={e => setAnnee(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Cote")} :
                            </Label>
                            <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              placeholder={props.t("Cote")}
                              value={cote}
                              onChange={e => setCote(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Chercheur")} :
                            </Label>
                            <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              placeholder={props.t("Chercheur")}
                              value={chercheur}
                              onChange={e => setChercheur(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Encadreur")} :
                            </Label>
                            <Input
                              lg="3"
                              type="text"
                              className="form-control"
                              placeholder={props.t("Encadreur")}
                              value={encadreur}
                              onChange={e => setEncadreur(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Description")} :
                            </Label>
                            <Input
                              lg="3"
                              type="textarea"
                              className="form-control"
                              placeholder={props.t("Description")}
                              value={description}
                              onChange={e => setDescription(e.target.value)}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1">
                              {props.t("Fichier PDF ")} :
                            </Label>
                            <Input
                              onChange={e => addFile(e)}
                              className="form-control"
                              type="file"
                              id="formFile"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                          className="text-center mt-4"
                        >
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning  mb-2 me-2"
                            onClick={() => props.history.push("/Memoires")}
                          >
                            {props.t("Annuler")}
                          </Button>
                        </div>
                      </Col>
                      <Col lg="6">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                          className="text-center mt-4"
                        >
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={edit}
                          >
                            {props.t("Confirmer")}
                          </button>
                        </div>
                      </Col>
                    </div>
                  </Row>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Spinner type="grow" className="ms-6" color="primary" />
                    </div>
                    <h4
                      style={{ textAlign: "center", marginTop: "2%" }}
                      className="ms-6"
                    >
                      {" "}
                      {"Chargement"} ...{" "}
                    </h4>
                  </div>
                )}
              </CardBody>
            </Card>
          </Row>
          <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(EditMemoires)
EditMemoires.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
